import React from "react"
import SoftPOS from "../../../components/body/pages/en-ng/business/softpos-payments"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const SoftPOSPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/softpos-payments/"}
      title="softPOS | Take Payment Anywhere with a Phone | Kuda Business"
      description="Take payment from any smartphone with softPOS from Kuda Business. Your customers can pay by card, transfer, USSD, QR code & from Kuda accounts."
    />
    <SoftPOS />
  </Layout>
)

export default SoftPOSPage
