import React from "react";

import KudaPOSTerminalIcon from "../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import InvoicingIcon from "../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import BizSalaryLoanIcon from '../../../../../assets/img/more-for-you/kuda-bizSalaryLoan.icon.inline.svg'
import VirtualPOSIcon from '../../../../../assets/img/more-for-you/kuda-virtual-icon.inline.svg'

export const moreForYou1 = [
  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },
  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <BizSalaryLoanIcon />,
    title: "Business Loan",
    subText: `Offer your staff loans with easy application, approval and disbursement through Kuda Business.`,
    linkTo: `/en-ng/business/loans/`
  },
]

export const moreForYou2 = [
  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
  one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },

  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <VirtualPOSIcon />,
    title: "Virtual POS",
    subText: `Empower your salespeople to accept payments instantly at all your business locations.`,
    linkTo: `/en-ng/business/virtual-pos/`
  },

]