import React from "react"
import ArrowRight from "../../../../../../assets/img/arrow-right-white.inline.svg"
import { HeroHeaderStyle } from '../styles/InnerPagesStyle';
import { Img } from "react-image";
import { Link } from "gatsby";

const InnerPageHeader = (props) => {


    const { title, subtitle, buttonName, buttonUrl, entryIllustration, fallback, isExternal } = props;

    return (
        <HeroHeaderStyle>
            <div className="kuda-entry--fold home-fold kuda-ignore--container career-noMarginTop business-home-fold">
                <div className="kuda-section--inner business-centered-header">
                    <div className="text-center justify-between flex flex-column">

                        <div className="business-header-container flex flex-column justify-center">

                            <h1 className="mx-auto business-header-title color-black">
                                {title}
                            </h1>
                            <p className="mx-auto business-header-subtext color-black">
                                {subtitle}
                            </p>
                            <div className="flex justify-center">


                                {typeof isExternal !== "undefined" ?
                                    <Link
                                        to={buttonUrl}
                                        className="dark-cta kuda-cta cta_button"
                                    >
                                        <span className="mr-3">  {buttonName}</span>
                                        <ArrowRight />
                                    </Link>
                                    :
                                    <a
                                        href={buttonUrl}
                                        className="kuda-cta dark-cta cta_button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span className="mr-3">{buttonName}</span>
                                        <ArrowRight />
                                    </a>
                                }

                            </div>
                        </div>
                        <div className="flex justify-center">
                            <div className="registration-cac-tic-illustrations bottom--spacing">
                                <Img src={entryIllustration} alt="explore_img" className="hero-illustration" id="tabletImage" loading="eager" loader={fallback} />

                            </div>

                        </div>
                    </div>

                </div>
                {/* </VizSensor> */}
            </div>
        </HeroHeaderStyle>
    )
}

export default InnerPageHeader;
